import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image";
import { MDXRenderer } from "gatsby-plugin-mdx"

import { Grid, GridCol, Layout, SEO, Video } from "../components"

class PostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    return (
      <Layout title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.excerpt}
        />
        {post.frontmatter.videos &&
        <Grid>
          {post.frontmatter.videos.map(item => (
            <GridCol className={'col-12 spacing-bottom--sm'}>
              <Video className={'spacing-bottom--sm'} videoUrl={item}/>
            </GridCol>
          ))}
        </Grid>
        }
        {post.frontmatter.images &&
          <Grid>
            {post.frontmatter.images.map((item, n) => (
              <GridCol className={'col-6'} key={`img-${n}`}>
                <Img className={'spacing-bottom--sm'} fluid={item.childImageSharp.fluid} />
              </GridCol>
            ))}
          </Grid>
        }
        <p className={'mb-0'}><strong>{post.frontmatter.date}</strong></p>
        <h2 className={'h6 spacing-bottom--sm'}>{post.frontmatter.title}</h2>
        <MDXRenderer>{post.body}</MDXRenderer>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM, YYYY")
        videos
        images {
         childImageSharp {
           fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid
           }
         }
        }
      }
    }
  }
`